@import './src/scss/variables';
@import './src/scss/app';

.color {
  &-swatch {
    width: 100%;
    height: 2rem;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEXv7+////9mUzfqAAAAFElEQVQIW2NksN/ISAz+f9CBGAwAxtEddZlnB4IAAAAASUVORK5CYII=);
      background-position: center;
    }
    div {
      z-index: 1;
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &-picker {
    &-wrapper {
      position: relative;
      text-align: left;
    }

    &-input-wrapper {
      display: none;
    }

    &-panel {
      width: 100% !important;
    }

    &-show-inline {
      position: relative;
    }

    &-row {
      display: flex;
      gap: 1rem;

      .color-picker-overlay {
        position: absolute;
        width: 100%;
        height: 320px;
        top: 0;
        left: 0;
        z-index: 2;
      }
    }

    &-grid {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEXv7+////9mUzfqAAAAFElEQVQIW2NksN/ISAz+f9CBGAwAxtEddZlnB4IAAAAASUVORK5CYII=);
      position: relative;
      width: 100%;
    }

    &-picker {
      display: none;
      position: absolute;
      width: 2rem;
      height: 2rem;
      background: none;
      box-sizing: content-box;
      z-index: 99;
      transform: translate(-50%, -50%);
      border: 2px solid #fff;
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &-grid-inner {
      width: 100%;
      height: 100%;
      cursor: crosshair;
    }

    &-overlay {
      background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
      pointer-events: none;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, transparent 0%, #000 100%);
      }
    }

    &-hue, &-opacity {
      cursor: crosshair;
      display: block;
      width: 2rem;
      height: 320px;
      background-color: white;
      position: relative;

      .color-picker-overlay {
        height: 100%;
      }
    }
  }
}

.canvas {
  height: 550px;
  overflow: auto;

  &__wrap {
    position: relative;
    width: 100%;
    height: 639px;
  }

  #canvas_picker {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAQMAAAC3R49OAAAABlBMVEXv7+////9mUzfqAAAAFElEQVQIW2NksN/ISAz+f9CBGAwAxtEddZlnB4IAAAAASUVORK5CYII=);
    width: 1136px;
    height: 100%;
    cursor: crosshair;
    object-fit: cover;
    object-position: center;
  }
}

#canvas_circle {
  display: none;
  // 	position: absolute;
  //   width: 2rem;
  //   height: 2rem;
  //   background: none;
  //   box-sizing: content-box;
  //   z-index: 99;
  //   transform: translate(-50%, -50%);
  //   border: 2px solid #fff;
  //   border-radius: 50%;
  //   box-shadow: 0 2px 4px rgba(#000, .2);
  //
  // 	&_preview {
  // 	  width: 100%;
  // 	  height: 100%;
  // 	  image-rendering: pixelated;
  // 	  border-radius: 50%;
  // 	}
}